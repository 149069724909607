

//
// Componente botón
//
.componente-boton
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    max-width: 100%;

    cursor: pointer;

    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.3rem;

    word-break: break-all;
    text-decoration: none;
    color: white;
    background: var(--bg-color);

    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow:ellipsis;
    overflow:hidden;

    &:hover
    {
        text-decoration: none;
        color: white;
        background: var(--hover-color);
    }

    p
    {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow:ellipsis;
        overflow:hidden;
    }
    strong
    {
        font-weight: 800;
    }
}
@media (max-width:768px) {
    .componente-boton{
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow:ellipsis;
        overflow:hidden;

        p {
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow:ellipsis;
            overflow:hidden;
        }
    }
}


//
// Componente agrupador
//
.componente-agrupador
{
    .componente-agrupador-header
    {
        background: var(--bg-color);
        color: var(--text-color);

        padding: 13px 0 13px 48px;
        margin: 0;

        .componente-agrupador-header-title
        {
            font-size: 30px;
            font-weight: 700;
            
            a
            {
                color: inherit;
                font-weight: inherit;
                &:hover
                {
                    color: inherit;
                }
            }
            p
            {
                margin: 0;
                padding: 0;
            }
        }
    }

    .componente-agrupador-content
    {
        .componente-agrupador-content-item:hover p{ font-weight:600 !important; }
        .componente-agrupador-content-item:hover{ padding: 7px 0 9px 0; margin: 16px 12px 0 12px; }
        .componente-agrupador-content-item
        {
            background: var(--bg-color);
            color: var(--text-color);


            margin-top: 16px;
            padding: 7px 12px 9px 12px;
            display: flex;


            .componente-agrupador-content-item-text
            {
                align-self: center;
                font-size: 16px;
                flex-grow: 1;

                display: -webkit-box !important;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow:ellipsis;
                overflow:hidden;
                p
                {
                    margin: 0;
                    padding: 0 10px;
                    word-break: break-all;
                }
                a
                { 
                    color: inherit;
                }
            }
            .componente-agrupador-content-item-icon:hover a img{ filter: invert(1) }
        
            .componente-agrupador-content-item-icon 
            {
                display: flex;
                align-items: center;
            }
        }
        @media (max-width:768px) {
            .componente-agrupador-content-item {
                .componente-agrupador-content-item-text {
                display: -webkit-box !important;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                text-overflow:ellipsis;
                overflow:hidden;
                }
            }
        }
    }
}