body {
    font-family: 'Encode Sans', sans-serif!important;
}

.navbar-nav {
    font-family: 'Encode Sans', sans-serif!important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6  {
    font-family: 'Encode Sans', sans-serif!important;
    letter-spacing: 0px!important;
    text-transform: initial!important;
}

.h1, h1:first-letter {
    text-transform: uppercase!important;
}

.h2, h2:first-letter {
    text-transform: uppercase!important;
}

.h3, h3:first-letter {
    text-transform: uppercase!important;
}

.h4, h4:first-letter {
    text-transform: uppercase!important;
}

.h5, h5:first-letter {
    text-transform: uppercase!important;
}

.h6, h6:first-letter {
    text-transform: uppercase!important;
}

a {
    color: #0072BB;
    background-color: transparent;
    font-weight: 500;
}

a:hover {
    color: #37BBED;
    text-decoration: underline;
}

footer a {
    color: white;
    font-weight: 500;
    transition: 0.3s;
}

footer a:hover {
    opacity: 0.7;
    transition: 0.3s;
    text-decoration: none;
    font-weight: 500;
}

.panel-card-link:hover {
    color: #fff!important;
    text-decoration: none!important;
}

.card-link:hover {
    font-weight: inherit!important;
}

.form-control:focus {
    border: 1px solid #0072BB!important;
    box-shadow: 0px 0px 0px 1px rgb(0 114 185)!important;
}

.logo-jm {
    height: 110px!important;
    width: auto!important;
}

.btn-outline-primary {
    color: #2fb5e6;
    border-color: #2fb5e6;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #2fb5e6;
    border-color: #2fb5e6;
}

.btn-outline-primary:focus {
    color: #fff;
    background-color: #2fb5e6;
    border-color: #2fb5e6;
}

.btn-conectar-igualdad, .btn-conectar-igualdad:focus, .btn-conectar-igualdad.focus, .btn-conectar-igualdad.disabled, .btn-conectar-igualdad:disabled,
.btn-conectar-igualdad:not(:disabled):not(.disabled):active, .btn-conectar-igualdad:not(:disabled):not(.disabled).active,
.show > .btn-conectar-igualdad.dropdown-toggle  {
    color: #fff!important;
    background-color: #0072BB!important;
    border-color: #0072BB!important;
}

.btn-conectar-igualdad:hover {
    background-color: #0481D2!important;
    border-color: #0481D2!important;
}

.carousel-control-next-icon.dark, .carousel-control-prev-icon.dark {
    background-color: #2fb5e6!important;
}

.carousel-dots li.slick-active div {
    background-color: #2fb5e6!important;
    border-color: #2fb5e6!important;
}

.carousel-dots li div {
    background-color: #adb5bd!important;
    border-color: #adb5bd!important;
}

.card-title {
    color: #adb5bd!important;
}

h1.display-5 span {
    font-size: 3rem;
    line-height: 1.2;
    color: #5a5a5a;
}

p.display-5 {
    font-size: inherit!important;
    line-height: inherit!important;
    color: #5a5a5a;
}

h5 span {
    color: #5a5a5a;
}

.img-event::before {
    content: '';
    width: 100%;
    height: 80px;
    background-color: #e2e2e2;
    position: absolute;
    clip-path: polygon(0 0,0 80%,100% 100%,100% 0);
}

img.group.list-group-image.img-fluid.card-img-top {
    opacity: 0;
}

.slick-slide img {
    display: initial;
}

.invalid-tooltip {
    background-color: #ce0000!important;
    color: #fff;
    height: auto;
    position: absolute;
    margin-bottom: -10px!important;
    width: 100%;
    padding: 12px!important;
    box-shadow: 0px 0px 0px 1px rgb(206 0 0);
    font-size: 1rem;
    top: auto;
}

.invalid-general-error {
    color: #FF0000;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
#searchResults {margin-top: -110px;}
.carousel-dots {margin-bottom: 0!important;}
}

@media only screen and (max-width: 1288px) {
	.main-img {padding: 50px 50px 30px 400px!important;}
	.s1{font-size: 35px!important;line-height: 40px!important;}
	.large {font-size: 100%;}
	.card-body-md {margin-top: 0px!important;}
	.icono-carousel-buscador {width: 40px!important; height: 40px!important;}
	.main-img p {font-size: 100%;}
	.main-img h1.display-5{font-size: 35px!important;line-height: 40px!important;}
}

@media only screen and (max-width: 1560px) {
h3 {
    font-size: 1.15rem!important;
	letter-spacing: 2px;
}
}

@media only screen and (min-width: 1025px) {
.icono-home {height: 130px;width: 130px;}
.icono-home-lg {height: 150px;width: 150px;}
.icono-home-xl {height: 250px;width: 250px;margin-top: 0px;}
.icono-buscador {height: 50px; width: 50px;}
.jm-buscador {max-height: 366px;}
.card-search {height: 400px;}
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
.s1{font-size: 20px!important;line-height: 20px!important;}
.large {font-size: 100%;}
h1, .h1 {font-size: 1.5rem;}
h2, .h2 {font-size: 1.5rem;}
h3 {font-size: 1.25rem!important}
h6, .h6 {font-size: 0.75rem!important}
.card-search {height: 400px!important;}
.jm-buscador {margin-top: 7px!important; max-height: 339px;}
.icono-home {height: 100%;width: 100%;}
.icono-home-lg {height: 160px;width: 160px;}
.icono-home-xl {height: 100px;width: 100px;margin-top: 0px;}
.max-ch {display: -webkit-box;-webkit-line-clamp: 5!important;-webkit-box-orient: vertical;overflow: hidden;}
.logos-footer {width: 100%!important;padding-left: 0%!important;padding-right: 0%!important; margin-bottom: 20px;}
.footer {min-height: 150px!important;padding: 0px!important;}
.legales {margin-top:10px!important;}
.icono {height: 40px!important;width: 40px!important;border-radius: 5px!important; margin-top:-17%!important}
.card-body.card-lg {margin-top: 110px!important;line-height: 16px;}
.card-title {font-size: 14px!important;}
.card-text:last-child {font-size:12px!important;}
.icono-buscador {width: 70px!important; height: 70px!important; border-radius: 5px!important;margin-top:30px!important;}
#sidebar .sidebar-header {padding: 26px 20px!important;}
#content {overflow: hidden;}
.card-body-md {margin-top:0px!important;font-size: 13px!important; line-height: 10px!important;}
.searchResultBox {height: 180px!important;}
.icono-carousel-buscador {display: none!important;}
}

.main-img p {
    font-size: 120%;
    font-weight: 300;
}

.footerDatos {
	text-align: right;
}

.vidActive {
	background-color: #1c2023;
	border-top: none;
	cursor: not-allowed;
}

.vidActive .card-title {opacity: 0.4!important;cursor: not-allowed;}
.vidActive p {opacity: 0.4!important;cursor: not-allowed;}
.vidActive .icono_play {opacity: 0.4!important;cursor: not-allowed;}
.vidGallery {max-height: 750px; overflow-y: scroll;}

.cool-link { color: #666;display:inline-block; margin:0;text-transform:uppercase; }
.cool-link:after {
  display:block;
  content: '';
  border-bottom: solid 2px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.cool-link:hover:after { transform: scaleX(1); }
.cool-link.fromRight:after{ transform-origin:100% 50%; }
.cool-link.fromLeft:after{  transform-origin:  0% 50%; }


li.nav-item.active:after {
    border-bottom: 2px solid #fff;
    content: "";
    display: block;
    margin: -10px auto;
    width: 62%;
    padding-top: 0px;
}

.attachedLabel {
    position: relative;
    left: 8px;
    top: 18px;
    background: white;
    padding: 0px 6px;
    letter-spacing: 3px;
    font-size: 13px;
    color: #000!important;
    font-weight: bold;
	text-transform: uppercase;
}
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: .2;
  transition: 0s;
}

.tc-1 {color:#50535C!important;}
.tc-2 {color:#e69732!important;}
.tc-3 {color:#37BBED!important;}
.tc-4 {color:#119d57!important;}
.tc-5 {color:#50535C!important;}
.tc-6 {color:#f7be4a!important;}
.tc-7 {color:#238da5!important;}
.tc-8 {color:#29A56A!important;}

.main-img {
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: auto;
    margin-top: 110px;
    margin-left: 0px;
    position: relative;
    padding: 50px 100px 50px 400px;
    color: white;
    min-height: 200px;
}

.main-img h1.display-5 {
    font-size: 3rem;
    line-height: 1.2;
    letter-spacing: 0px;
    text-transform: capitalize;
    font-family: 'Encode Sans', sans-serif!important;
}

.navbar-dark .navbar-nav .nav-link {
	font-weight: 900;
}

.hiddenRow {
    padding: 0px!important;
}

/* Usuario Logueado */
.profile
{
    min-height: 355px;
    display: inline-block;
    }
figcaption.ratings
{
    margin-top:20px;
    }
figcaption.ratings a
{
    color:#f1c40f;
    font-size:11px;
    }
figcaption.ratings a:hover
{
    color:#f39c12;
    text-decoration:none;
    }
.divider
{
    border-top:1px solid rgba(0,0,0,0.1);
    }
.emphasis
{
    border-top: 4px solid transparent;
    }
.emphasis:hover
{
    border-top: 4px solid #1abc9c;
    }
.emphasis h2
{
    margin-bottom:0;
    }
span.tags
{
    background: #1abc9c;
    border-radius: 2px;
    color: #f5f5f5;
    font-weight: bold;
    padding: 2px 4px;
    }
.dropdown-menu
{
	background-color: #fff;
    color: #34495e;
    width: 250px;
    margin-left: 0px;
    left:0;
    }
.dropdown-menu .divider
{
    background:none;
    }
.dropdown-menu>li>a
{
    color:#f5f5f5;
    }
.dropup .dropdown-menu
{
    margin-bottom:10px;
    }
.dropup .dropdown-menu:before
{
    content: "";
    border-top: 10px solid #34495e;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    z-index: 10;
    }

span.badge.badge-xs.badge-secondary {
    font-size: 50%;
}
.tech_logo {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-height: 100px;
	width: 100%;
}

.jm-buscador {
    position: absolute;
    margin-top: 0;
    left: 30px;
    width: 350px;
    bottom: 0;
}

.lh-12 {line-height: 12px;}
.lh-15 {line-height: 15px;}
.lh-20 {line-height: 20px;}
.lh-30 {line-height: 30px;}
.lh-40 {line-height: 40px;}
.lh-50 {line-height: 50px;}
.main_content {
	margin-top: 120px;
}
/*Password strength meter */
*:focus {
  outline-style: none;
}

#progress {
	height: 8px;
	width: 100%;
	margin-top: 0.6em;
	overflow: hidden;
	border-radius: 3px;
	background-color: #f5f5f5;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}

#progress-bar {
  width: 0%;
  height: 8px;
  transition: width 500ms linear;
}

.tab_buttons {
	width: auto;
	text-align: center;
}

.progress-bar-danger {
  background: #d00;
}

.progress-bar-warning {
  background: #f50;
}

.progress-bar-success {
  background: #080;
}
/*Load more */
  ul{list-style:none;}
  a{text-decoration:none;}

.more {
	background-color: #f1f1f1;
	border-radius: 5px;
	padding: 5px 10px;
    color: #333;
    cursor:pointer;
	margin-left: 0px;
	margin-right: 15px;
	margin-top: 15px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: 'Encode Sans', sans-serif!important;
	font-size: 14px;
}

.more:hover {
	background-color: #e2e2e2;
}

.term-list {
	padding-left: 20px;
}

/*MAP SVG */
.mapsvg-controller-view-wrap.nano {
    position: absolute!important;
    top: 0!important;
    left: 0!important;
    bottom: 0!important;
    right: 0!important;
}

.mapsvg-popover .mapsvg-controller-view-toolbar {
    height: 20px!important;
    width: 20px!important;
    float: right!important;
}


/*Cards*/
.cardRecursos {
  	position: relative;
  	display: -ms-flexbox;
  	display: flex;
  	-ms-flex-direction: column;
  	flex-direction: column;
  	min-width: 0;
  	word-wrap: break-word;
  	background-color: #fff;
 	background-clip: border-box;
 	border: none;
  	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.15);
	-moz-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.15);
	box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.15);
	max-width: 350px;
}

.cardRecursos > hr {
  margin-right: 0;
  margin-left: 0;
}

.cardRecursos > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.cardRecursos > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.cardRecursos > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.cardRecursos-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.8rem 1.2rem;
}

.cardRecursos-title {
  	margin-bottom: 0.75rem;
	color: #5c318d;
    line-height: 1;
    font-weight: 600
}

.cardRecursos-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.cardRecursos-text:last-child {
  margin-bottom: 0;
	margin-top: 20px;
	font-size: 14px!important;
}

.cardRecursos-link:hover {
  text-decoration: none;
}

.cardRecursos-link + .cardRecursos-link {
  margin-left: 1.25rem;
}

.cardRecursos-header {
      padding: 0px;
    margin-bottom: 0;
    background-color: transparent;
    position: absolute;
    z-index: 1002;
    right: 10px;
	top: 10px;
}

.cardRecursos-header:first-child {
  border-radius: 0px;
}

.cardRecursos-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.cardRecursos-footer {
  padding: 10px;
  background-color: white;
  margin-top: -30px;
}

.cardRecursos-footer:last-child {
  border-radius: 0px;
}

.cardRecursos-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.cardRecursos-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.cardRecursos-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.cardRecursos-img,
.cardRecursos-img-top,
.cardRecursos-img-bottom {
  -ms-flex-negative: 0;
  	flex-shrink: 0;
  	width: 100%;
	border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.cardRecursos-img,
.cardRecursos-img-top {
  	width: 100%;
    height: auto;
  	-webkit-clip-path: polygon(0% 0%, 0% 80%, 100% 100%, 100% 0);
  	clip-path: polygon(0% 0%, 0% 80%, 100% 100%, 100% 0);
}

.cardRecursos-img,
.cardRecursos-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.cardRecursos-deck .cardRecursos {
  margin-bottom: 15px;
}

.cardRecursos-body.cardRecursos-lg {
	margin-top: 48%;
}

.cardRecursos-body.cardRecursos-body-md {
	margin-top: 80px!important;
}

.cardRecursos-body.cardRecursos-sm {
	margin-top: 130px!important;
}

.cardRecursos-header.cardRecursos-header-lg {
	top: 0px!important;
	right: 0px!important;
}

.cardRecursos-img,
.cardRecursos-img-top-buscador,
.cardRecursos-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.cardRecursos-img,
.card-img-top-buscador {
  	width: 100%;
    height: auto;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card.card-dark {
    background: #343a40;
    border-radius: 0px;
    margin-bottom: 0px!important;
}

.fa-small {
	width: 5px!important;
	height: 5px!important;
	padding: 0px!important;
	color: #fff!important;
	position: relative;
}

.carousel-header {
    height: 90px!important;
}

.carousel-body {
    height: 75px!important;
    text-align: left;
}

.carousel-dots {
    margin: 0px 30px -1% 0px;
}

/*Social buttons */
.fa-so {
  	font-size: 30px;
  	width: 30px;
	height: 30px;
  	text-align: center;
  	text-decoration: none;
	padding:7px;
	border-radius: 5px;
	margin: 0px 5px;
}

/* Facebook */
.fa-facebook {
  	background: #3B5998;
  	color: white;
	transition: 0.3s;
}

.fa-facebook:hover {
  	background: #293e65;
  	color: white;
	transition: 0.3s;
}

/* Twitter */
.fa-twitter {
  	background: #55ACEE;
  	color: white;
	transition: 0.3s;
}

.fa-twitter:hover {
  	background: #3c7cad;
	color: white;
	transition: 0.3s;
}

/* Whatsapp */
.fa-whatsapp {
  	background: #25D366;
  	color: white;
	transition: 0.3s;
}

.fa-whatsapp:hover {
  	background: #24a259;
	color: white;
	transition: 0.3s;
}

/* Email */
.fa-envelope {
  	background: #d32525;
  	color: white;
	transition: 0.3s;
}

.fa-envelope:hover {
  	background: #a02121;
	color: white;
	transition: 0.3s;
}

/* Like */
.fa-heart-o {
  	background: #fff3f3;
  	color: #E40F5C!important;
	transition: 0.3s;
}

.fa-heart-o:hover {
  	background: #f5e2e2;
  	color: #E40F5C!important;
	transition: 0.3s;
}

.fa-heart {
  	background: #ffe9e9;
  	color: #E40F5C!important;
	transition: 0.3s;
}

/* Like button */
.btn:active,
.btn:focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
}

.swap {
	cursor: pointer;
}

button > span {
  color: #E40F5C!important;
  font-size:20px;
}

.font-22px {
	font-size: 22px!important;
}

.btn-round {
	padding: 5px 15px;
	border-radius: 50%;
	text-align: center;
}


.search_main {
    position: absolute;
}

#sresult {
    position: relative;
    background-color: #fff;
    border: 1px solid #CCCCCC;
    display: none;
}

#searchResults {
	display:none;
}

.searchResultBox {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    margin-top: -125px;
    position: relative;
    z-index: 1003;
	height: 220%;
    padding: 5px 0px 60px 0px;
}

.jm_buscador {
	position: absolute;
    width: 115%;
    height: 115%;
    margin-left: -50px;
    margin-top: -1px;
}
#lacita {
	position:relative;
	top: -8px;
	font-size: 12px;
	right: 10px;

}
/*Card Search*/
.card-search {
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	border-radius: 10px;
	margin-top: 40px;
	margin-bottom: 30px;
	padding: 20px;
	height: 400px;
}

.card-login3 {
	background-color: white;
	width: 100%;
	height: auto;
	min-height: 50vh;
    position: relative;
	border-radius: 10px;
	margin-top: 50px;
	margin-bottom: 30px;
	padding: 20px 70px;
}

.card-login2 {
	background-color: white;
	width: 100%;
	height: auto;
	min-height: 50vh;
    position: relative;
	border-radius: 10px;
	margin-top: 100px;
	margin-bottom: 30px;
	padding: 20px 70px;
}

.card-login {
	background-image:url(../images/bkg-login.jpg);
	width: 100%;
	height: auto;
	min-height: 50vh;
    position: relative;
	border-radius: 10px;
	margin-top: 140px;
	margin-bottom: 30px;
	padding: 20px;
}


.card-login img {
    position: absolute;
    left: 0;
    bottom: 0;
	max-width: 45%;
	max-height: 100%;
}

.card-registro {
	background-image:url(../images/bkg-register.jpg);
	width: 100%;
	height: auto;
    position: relative;
	border-radius: 10px;
	margin-top: 140px;
	margin-bottom: 30px;
	padding: 20px;
}

.card-registro img {
    position: absolute;
    left: 0;
    bottom: 0;
	max-width: 45%;
	max-height: 100%;
}

.card-recupero {
	background-image:url(../images/bkg-recupero.jpg);
	width: 100%;
	height: auto;
	min-height: 50vh;
    position: relative;
	border-radius: 10px;
	margin-top: 140px;
	margin-bottom: 30px;
	padding: 20px;
}


.card-recupero img {
    position: absolute;
    left: 0;
    bottom: 0;
	max-width: 50%;
	max-height: 100%;
}

.card-api {
	background-image:url(../images/trama-1.jpg);
	width: 100%;
	height: auto;
	min-height: 300px;
    position: relative;
	border-radius: 10px;
	margin-bottom: 30px;
	padding: 20px;
	font-weight: 500;
	color: #fff;
}


.card-api img {
    position: absolute;
    left: -50px;
    top: -50px;
    max-width: 120%;
    max-height: 120%;
}

.card-institucional {
	background-image:url(../images/trama-3.jpg);
	width: 100%;
	height: auto;
	min-height: 300px;
    position: relative;
	border-radius: 10px;
	margin-bottom: 30px;
	padding: 20px;
	font-weight: 500;
	color: #fff;
}


.card-institucional img {
    position: absolute;
    left: 0px;
    bottom: -125px;
    width: 100%;
    height: auto;
}

.card-oai {
	background-image:url(../images/trama-3.jpg);
	width: 100%;
	height: auto;
	min-height: 300px;
    position: relative;
	border-radius: 10px;
	margin-bottom: 30px;
	padding: 20px;
	font-weight: 500;
	color: #fff;
}


.card-oai img {
    position: absolute;
    left: -50px;
    top: -50px;
    max-width: 120%;
    max-height: 120%;
}

.card-rea {
	background-image:url(../images/trama-2.jpg);
	width: 100%;
	height: auto;
	min-height: 350px;
    position: relative;
	border-radius: 10px;
	margin-bottom: 30px;
	padding: 20px;
	font-weight: 500;
	color: #fff;
}


.card-rea img {
    position: absolute;
    left: 0px;
    bottom: -350px;
    width: 90%;
    height: auto;
    margin-left: 5%;
}

.bubble {
	height: auto;
	width: 100%;
	min-width: 200px;
	background: white;
	display: block;
	margin: 30px 0px 0px 0px;
	border-radius: 5px;
	text-align: left;
	padding: 10px;
}

.pointer {
	height: 30px;
    width: 30px;
    background: white;
    margin: 0px 0px -10px 70px;
    transform: rotate(45deg);
    border-radius: 0 0 5px 0;
    margin-top: -15px;
    position: relative;
    left: calc(0.1vw - 50px);
}

.blurred {
	background: gray;
	filter: blur(20px);
	position: relative;
	top: -315px;
/* 	transform: scale(1.05); */
	z-index: -1;
}

/*Select2*/
.select2-container--default .select2-selection--single {
	border-radius: 0px!important;
	background-color: #f1f1f1!important;
	border: none!important;
}

.select2-container .select2-selection--single {
	height: 38px!important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 38px!important;
	font-family: 'Encode Sans', sans-serif!important;
    letter-spacing: 1px!important;
	font-size: 14px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	margin-top: 2px!important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
	background-color: #3dcfd0!important;
}

/*Checkbox*/
/* The container */
.container-ch {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
    padding-left: 25px;
    padding-right: 15px;
	font-size: 14px;
	font-family: 'Encode Sans', sans-serif!important;
	letter-spacing: 1px;
	font-weight: 700;
}

/* Hide the browser's default checkbox */
.container-ch input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container-keyboard {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 9;
    margin-top: 1px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
	border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.container-ch :hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-ch  input:checked ~ .checkmark {
  background-color: #34babc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-ch  input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-ch .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*JM colors*/
.bg-card {
	color: #fff;
	transition: all .3s ease-in-out;
}

.bg-card:hover{
	transform: scale(1.03);
}

.jm-bgk-img {
    background-image: none;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 20px;
    min-height: calc(100% - 30px);
}

.jm-bgk-img2 {
	background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 20px;
	height: 350px;
}

.jm1 {background-color:#37BBED;}
.jm2 {background-color:#37BBED;}
.jm3 {background-color:#37BBED;}
.jm4 {background-color:#50535C;}
.jm5 {background-color:#37BBED;padding: 10px 0px;}
.jm6 {background-color:#37BBED;padding: 10px 0px;}
.jm7 {background-color:#37BBED;padding: 10px 0px;}
.jm8 {background-color:#37BBED;padding: 10px 0px;}
.jm9 {background-color:#37BBED;padding: 10px 0px;}
.jm10 {background-color:#37BBED;padding: 10px 0px;}
.jmCard {padding: 10px 0px;}

.JMAulas {background-image: url(../images/trama-4.jpg);}
.JMDatos {background-image: url(../images/trama-3.jpg);}
.JMRecursos {background-image: url(../images/trama-2.jpg);}
.JMDirectores {background-image: url(../images/trama-4.jpg);}
.JMDocentes {background-image: url(../images/trama-3.jpg);}
.JMEstudiantes {background-image: url(../images/trama-2.jpg);}

.max-ch {
	display: -webkit-box;
  	-webkit-line-clamp: 4;
  	-webkit-box-orient: vertical;
  	overflow: hidden;
}
/*Viewpoint - list or gird */
.view-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
}
.thumbnail
{
    margin-bottom: 30px;
    padding: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.item.list-group-item
{
    float: none;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 1rem;
    border: 0;
	margin-top: 0px;
}
.item.list-group-item .img-event {
    float: left;
    width: 30%;
	position: relative;
	top: 0px;
	padding-bottom: 10px;
}

.item.list-group-item .list-group-image
{
    margin-right: 10px;
}
.item.list-group-item .thumbnail
{
    margin-bottom: 0px;
    display: inline-block;
}
.item.list-group-item .caption
{
    float: left;
    width: 70%;
    margin: 0;
	position: relative;
	top: 0px;
}

.item.list-group-item:before, .item.list-group-item:after
{
    display: table;
    content: " ";
}

.item.list-group-item:after
{
    clear: both;
}

.item.list-group-item .card-footer {
    width: 70%;
    float: right;
    top: 0px;
    position: relative;
}

.card-footer2 {
    padding: 10px;
    background-color: transparent;
    position: absolute;
    top: 45%;
	height: 100px;
	margin-top: -50px;
    margin: auto;
	left: 50%;
	width: 100px;
	margin-left: -75px;
}

/* --------------- Carousel ----------------*/

.carousel-item {
  	height: auto;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: auto;
}
.carousel-control-prev, .carousel-control-next {
	height: 120px;
}
.slider-thumb {
	position: absolute;
	top: 40px;
	left: 80px;
	height: 320px;
	border-radius: 0px;
	-webkit-box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.75);
}
.s1 {
	font-family: 'Encode Sans', sans-serif!important;
	font-weight: 800!important;
	font-size: 40px;
	text-transform: uppercase;
}

/*Search button*/
.searchBox {
	display: none;
	position: fixed;
    top: 80px;
    left: 70px;
    transform: translate(-50px,50px);
    background: #333;
    height: 80px;
	margin-top: 0px;
	padding: 0px;
    border-radius: 10px;
    z-index: 1003;
}

.searchBox:hover > .searchInput {
    width: 300px;
    padding: 0 6px;
	margin-left: 10px;
}

.searchBox:hover > .searchButton {
  	background: transparent;
    color: #2f3640;
    background-image: url(../images/icono_zoom.svg);
    background-repeat: no-repeat;
    background-position: left;
    cursor: pointer;
    height: 100px;
    width: 100px;
    margin-top: -15px;
}

.searchButton {
    float: left;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    background-image: url(../images/icono_jm.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-left: 0px;
    border: 0px!important;
    margin-top: -15px;
    margin-right: -10px;
}

.searchInput {
    border: none;
    background: none;
    outline: none;
    float: right;
    padding: 0;
    color: white;
    font-size: 16px;
    transition: 0.4s;
    line-height: 80px;
    width: 0px;
    margin-left: 0px;
}

.searchButton:focus {
	outline: none!important;
	cursor: pointer;
}

.searchBoxVisible {
	position: fixed;
    top: 80px;
    left: 70px;
    transform: translate(-50px,50px);
    background: #333;
    height: 80px;
	margin-top: 5px;
	padding: 0px;
    border-radius: 10px;
    z-index: 1003;
}

.searchBoxVisible:hover > .searchInput {
    width: 300px;
    padding: 0 6px;
	margin-left: 10px;
}

.searchBoxVisible:hover > .searchButton {
  	background: transparent;
    color: #2f3640;
    background-image: url(../images/icono_zoom.svg);
    background-repeat: no-repeat;
    background-position: left;
    cursor: pointer;
    height: 100px;
    width: 100px;
    margin-top: -15px;
}

.searchButton {
    float: left;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    background-image: url(../images/icono_jm.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-left: 0px;
    border: 0px!important;
    margin-top: -15px;
    margin-right: -10px;
}

.searchInput {
    border: none;
    background: none;
    outline: none;
    float: right;
    padding: 0;
    color: white;
    font-size: 16px;
    transition: 0.4s;
    line-height: 80px;
    width: 0px;
    margin-left: 0px;
}

.searchButton:focus {
	outline: none!important;
	cursor: pointer;
}

/* Button - Ayuda */
.floating_btn {
	position: fixed;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	bottom:40px;
	right:40px;
	background-color: #FFEA00;
	box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: 1s transform cubic-bezier(.155,1.105,.295,1), 0.5s box-shadow, 2s -webkit-transform cubic-bezier(.155,1.105,.295,1), 0.5s, border-radius 0.5s;
	background-image: url(../images/icono_jm.svg);
	background-repeat: no-repeat;
	background-position: right;
	cursor: pointer;
	z-index:1001;
}

.floating_btn:hover {
	background-color: #333;
	border-radius: 5px;
	width: 250px;
}

.floating_text {
  color: transparent;
  font-size: 16px;
  position: relative;
  top: 50%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.floating_text:hover {
	color: white;
	padding-right: 150px;
	padding-left: 30px;
	margin-left: 15px;
}

.logo-jm {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
}
main > .container {
  	padding: 120px 15px 0;
	height: auto;
}

.table {color:#000;}

/*Footer*/
.footer {
  	background-color: #2EB5E6;
	min-height: 160px;
	margin-top: 50px;
	padding-bottom:0px!important;
}

.footer > .container {
	padding: 15px 15px 20px 15px;
}

.legales {
	background-color: #fff !important;
	color: #333;
	padding: 15px 0px;
	line-height: 15px;
	margin-top:29px;
}

code {
  font-size: 80%;
}

.logo-left {
	float: right;
	margin-left: auto;
	position: absolute;
	right: 20px;
}


.text-red {
	color: #E40F5C!important;
}

.text-blue {
	color: #26b9ec!important;
}

.text-teal {
	color: #34babc!important;
}

a.text-blue:hover {
	color: #26b9ec!important;
}

.text-lime {
	color: #d2e23e!important;
}

a.text-lime:hover {
	color: #b1bd40!important;
}

.bg-lime {
	background-color: #d2e23e!important;
}

.text-orange {
	color: #FC9700;
}

a.text-orange:hover {
	color: #fc5d00!important;
}

.bg-orange {
	background-color: #FC9700!important;
}

.separator {
	height: 3px;
	background-color: #26b9ec;
	border-radius: 5px;
	max-width: 100px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}

.bg-title {
	background-color: #26b9ec !important;
	color: #fff;
	width: 100%;
	padding: 7px 10px 10px 10px;
	margin: 0px;
}

.table th, .table td {
	padding: 0.5rem;
	font-size: 14px
}

div#accordion {
    width: 100%;
}

.panel-heading {
	background-color: #2DC3EE;
    color: #fff;
    width: 100%;
	cursor: pointer;
	border-bottom: 1px solid #fff;
	padding-right: 20px;
}

.panel-heading:hover {
	background-color: #2eafd4;
}

.panel-body {
    width: 100%;
    padding: 20px;
}

.panel-title {
  position: relative;
	padding: 12px 20px 8px 20px;
}

.panel-title::after {
	content: "\f107";
	color: #fff;
	top: 14px;
	right: 0px;
	position: absolute;
    font-family: "FontAwesome"
}

.panel-title[aria-expanded="true"]::after {
	content: "\f106";
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.navbar {
    padding: 15px 10px;
    background: #333;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

.navbar.dark {
	background-color: #2EB5E6;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
	margin-top: 110px;
}

#sidebar {
    min-width: 300px;
    max-width: 300px;
    background: #f9fafb;
    color: #333;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: bottom left;
}

#sidebar.active {
    margin-left: -300px;
    transform: rotateY(100deg);
}

#sidebar .sidebar-header {
    padding: 26px 0px 27px 20px;
    background: #f9fafb;
	border-bottom: 1px solid #dcdcdc;
}

#sidebar .sidebar-body {
    padding: 20px;
    background: #f9fafb;
	border-bottom: 1px solid #dcdcdc;
}

#sidebar ul.components {
    padding: 0px;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
   	padding: 10px;
    display: block;
    border-bottom: 1px solid #f1f1f1;
    font-family: 'Encode Sans', sans-serif!important;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1px;
    color: #3d3d3d;
    background-color: #e0e0e0;
}
#sidebar ul li a:hover {
    color: #333;
    background: #d4d4d4;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #333;
    background: #f9fafb;
}


a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #f9fafb;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article, a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content2 {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
	overflow: hidden;
}

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
	overflow: hidden;
}

#sidebarCollapse {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
}

#sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #555;
    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
}
#sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media screen and (max-width: 991px) {
    .navbar-collapse {background-color: #333;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;margin-left: 0px;margin-right: 0px;padding-right: 20px;padding-left: 20px;margin-top: -5px;line-height: 40px;padding-bottom: 20px;}
}

@media screen and (max-width: 768px) {
	.hidden-xs{display:none!important;}
.main-img {padding: 50px 20px 30px 20px!important; margin-bottom: -10px; margin-top: 90px; min-height: 90px;}
.s1 {font-size: 22px!important; line-height: 24px!important;}
.footer {/* max-height: 200px; */ padding: 0px!important; min-height: 230px!important;}
.legales {font-size:12px; margin-top:20px;}
p {font-size: 14px;}
body {font-size:0.9rem;}
h1 {font-size: 23px;}
h3 {font-size: 18px;}
h4 {font-size: 20px;}
#content {width: 100%;padding: 0px;min-height: 100vh;transition: all 0.3s;margin-left: -50px;}
.jm1 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm2 {height: 200px!important; margin-bottom:20px; margin-top:0px!important;}
.jm3 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm4 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm5 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm6 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm7 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm8 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm9 {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jmCard {height: 300px!important; margin-bottom:20px; margin-top:0px!important; padding: 0px!important;}
.jm_buscador {position: relative;width: 200px;height: 200px;margin-left: auto;margin-top: auto;}
.max-ch {display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;}

#content {margin-top:20px;}
.logo-educar {padding-left: 20px; width: 100%;}
.logo-jm {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    max-height: 94px;
}
.logos-footer {
    width: 100%!important;
    height: auto;
    position:relative!important;
    padding-left:0%;
    padding-right: 0%;
}

h2 {font-size: 20px!important;margin-top:0px!important;line-height: 26px;}
h3 {font-size: 16px!important;margin-top:0px!important;}
h5 {font-size: 18px;}
h6 {line-height: 22px;}
.lh-20 {line-height: 20px!important;}
.lh-30 {line-height: 22px!important;}
.lead {font-size: 15px;}
.display-5 {font-size: 2rem;line-height: 1.2;}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {font-size: 13px;padding: 6px;max-height: 40px;}
.slider-thumb {display: none;}
.bubble {padding: 4px;}
.pointer {width: 20px!important; height: 20px!important; margin-top:-10px;}
.btn-outline-primary {padding: 0px 10px!important;font-size: 20px!important;}
.floating_btn {top: 87%!important;right: 20px;}
.jm-buscador {margin-top: 95px;width: 90%;left: 5%;}

.panel-group {padding: 0px 20px;}
.panel-title {font-size: 16px; padding-right: 40px;}
.panel-body {font-size: 14px;}

.table {font-size: 0.9rem;}

.navbar {margin-bottom: 0px; max-width: 100vw;}
.navbar-collapse {background-color: #333;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;margin-left: 0px;margin-right: 0px;padding-right: 20px;padding-left: 20px;margin-top: -5px;line-height: 40px;padding-bottom: 20px;}
.navbar {margin-top:0px;max-width: 100%;}

.searchBox {display: none;position: fixed;top: 55px;left: 60px;transform: translate(-50px,50px);background: #333;height: 60px;margin-top: 0px;padding: 0px;border-radius: 10px;z-index: 1003;}
.searchBox:hover > .searchInput {min-width: 330px;padding-left: 70px; margin-left: 20px;}
.searchBox:hover > .searchButton {background: transparent;color: #2f3640;background-image: url(../images/icono_zoom.svg);background-repeat: no-repeat;background-position: left;cursor: pointer;height: 80px;width: 80px;margin-top: -70px!important; position: relative; z-index: 1004;}
.searchButton {float: left;width: 80px!important;height: 80px!important;border-radius: 10px;background: transparent;display: flex;justify-content: center;align-items: center;transition: 0.4s;background-image: url(../images/icono_jm.svg);background-repeat: no-repeat;background-position: center;cursor: pointer;margin-left: 0px;border: 0px!important;margin-top: -10px!important;margin-right: -10px;}
.searchInput {border: none;background: none;outline: none;float: right;padding: 0;color: white;font-size: 14px;transition: 0.4s;line-height: 60px!important;width: 0px;margin-left: 0px;}
.searchButton:focus {outline: none!important;cursor: pointer;}
.searchBoxVisible:hover > .searchInput {width: 100px;padding: 0 6px;}

.searchResultBox {top: 7.6rem; height: 190px;}

.card-title {font-size: 18px;}
.card-search {padding:20px;height: 250px;}
.card-body.card-lg {margin-top: 165px;}
.card-text:last-child{margin-top:0px!important;}
.carousel-inner {max-height: 360px;}
.carousel {margin-top:40px;}
.carousel-caption {margin-left: 20px;bottom: 0px;}
.carousel-control-prev-icon.dark {background-image: url(../images/arrow_left-dark.svg);margin-left: 0px;}
.carousel-control-next-icon.dark {background-image: url(../images/arrow_right-dark.svg);margin-right: 0px;}

.icono-sm {background-position: center center;background-size:contain;height: 120px;width: 120px;position: relative;margin-right: auto;margin-left: auto;margin-bottom: 0px;margin-bottom: 20px;}
.icono {height: 60px;width: 60px;}
.icono-buscador {width:50px;height: 50px;}
.icono-home {width: 150px; height: 150px;}
.icono-home-lg {height: 120px;width: 120px;}
.icono-home-xl {height: 130px;width: 130px;}

#sidebarCollapse {margin-top: -5px; margin-left:-10px;}
#sidebar {margin-left: -200px;transform: rotateY(90deg); min-width: 250px;}
#sidebar.active {margin-left: 0;transform: none;margin-right: 20%;}
#sidebarCollapse span:first-of-type,
#sidebarCollapse span:nth-of-type(2),
#sidebarCollapse span:last-of-type {transform: none;opacity: 1;margin: 5px auto;}
#sidebarCollapse.active span {margin: 0 auto;}
#sidebarCollapse.active span:first-of-type {transform: rotate(45deg) translate(2px, 2px);}
#sidebarCollapse.active span:nth-of-type(2) {opacity: 0;}
#sidebarCollapse.active span:last-of-type {transform: rotate(-45deg) translate(1px, -1px);}
#sidebar .sidebar-header {padding: 26px 20px;}
.wrapper {margin-top:90px;overflow: hidden;}
.container-ch {font-size: 12px; letter-spacing: 0px; line-height: 24px;}
.checkmark {top: 2px;}

.card-registro img {display:none;}
.card-login img {display:none;}
.card-recupero img {display:none;}

.tech_logo {width: auto; height: 50%; margin-left: auto; margin-right: auto;}
.tab_buttons {width: 50%;}

.card-header2 {left: 0px!important; top: 0px!important;margin-bottom: 0px;}
.breadcrumb {margin-top: -20px;margin-left: -10px;margin-bottom: 20px;}
.footerDatos {text-align: center;}

.cool-link { color: #666;display:inline-block; margin:0;text-transform:uppercase; }

.cool-link:after {
  display:block;
  content: '';
  border-bottom: solid 2px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.cool-link:hover:after { transform: scaleX(1); }
.cool-link.fromRight:after{ transform-origin:100% 50%; }
.cool-link.fromLeft:after{  transform-origin:  0% 50%; }

li.nav-item.active:after {
    border-bottom: 2px solid #fff;
    content: "";
    display: block;
    margin: -10px auto;
    width: 50%;
    padding-top: 0px;
    margin-bottom: 10px;
    left: 30px;
    position: absolute;
}
.textHighlight {text-align: center;}
#content2 {
    width: 100%;
    padding: 0px;
    min-height: 100vh;
    transition: all 0.3s;
    margin-left: -50px;
    margin-top: 25px;
}
	.breadcrumb {display: none!important;}

nav.navbar.navbar-expand-lg.navbar-dark.dark.fixed-top {
    margin-top: -20px;
}

.slick-list {
    height: 100%;
}

.carousel-dots {margin-bottom: -15px!important;}
}

@media screen and (max-width: 767px) {
    .carousel-container { margin-left: 0px!important;}
}

.slick-list {
    text-align: center;
}


/* ------- Recursos List ----------- */

    .treeview-animated.w-20 {
      width: 20rem;
      font-size: 14px;
    }

    .treeview-animated h6 {
      font-size: 1.4em;
    }

    .treeview-animated ul {
      position: relative;
      list-style: none;
      padding-left: 0;
    }

    .treeview-animated-list li {
      padding: 0.2em 0 0 0.2em;
    }

.treeview-animated-element {
	padding: 10px;
	cursor: pointer;
	transition: all .1s linear;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
	border: none;
    }

    .treeview-animated-element:hover {
            	color: #3e454b;
    background-color: #f8f9fa;
    border-left: 4px solid #1bcaca;
	padding-left: 15px;
	transition: 0.2s;
    }

    .treeview-animated-element.opened {
      color: #f8f9fa;
      background-color: #1bcaca;
		padding-left:6px;
    }

    .treeview-animated-element.opened:hover {
      color: #f8f9fa;
      background-color: #1bcaca;
    }

    .treeview-animated-items .nested::before {
		content: "";
		display: block;
		position: absolute;
		background-color: #e5e8eb;
		left: 0px;
		width: 1px;
		height: 100%;
	}

    .treeview-animated-items .closed {
      display: block;
      padding: 10px;
      margin-right: 0;
      border-top-right-radius: 0.3em;
      border-bottom-right-radius: 0.3em;
    }

    .treeview-animated-items .closed:hover {
            	color: #3e454b;
    background-color: #f8f9fa;
    border-left: 4px solid #1bcaca;
	padding-left: 15px;
	transition: 0.2s;
    }

    .treeview-animated-items .open {
      transition: all .1s linear;
      background-color: #1bcaca;
    }

    .treeview-animated-items .open span {
      color: #f8f9fa;
    }

    .treeview-animated-items .open:hover {

      color: #fff;
      background-color: #1bcaca;
    }

    .treeview-animated ul li .open div:hover {
      background-color: rgb(50, 160, 255);
    }

    .treeview-animated-items .closed .fa-angle-right {
      transition: all .1s linear;
      font-size: .8rem;
    }

    .closed .fa-angle-right.down {
      position: relative;
      color: #f8f9fa;
      transform: rotate(90deg);
    }
.font30 {
	font-size: 30px!important;
	-webkit-text-stroke: 2px white;
}

.closeList {
	font-size: 25px!important;
	-webkit-text-stroke: 4px white;
	margin-top: 9px;
	margin-right: 10px;
	color: #141414;
}

.font18 {
	font-size: 18px!important;
}

.btn-link {
    font-weight: 400;
    color: #34babc;
    text-decoration: none;
}

.dropdownListados {
    display: inline-block;
    width: 100%;
    height: calc(2em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    border: 1px solid #141414;
    border-radius: 0.3rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
	text-align: left;
	background: #fff;
}

.lh-36 {line-height: 36px;}

.sortable-chosen {background-color: #f9f9f9;}

.deleteListItem {
    position: relative;
    left: 180px;
    top: -40px;
}

.sideNavs {
    margin: 10px 0px 10px 0px;
    border-radius: 0px!important;
    transition: 0.4s;
    color: #2b2523;
    padding: 0px 10px;
    border-top: none!important;
    border-right: none!important;
    border-bottom: none!important;
    border-left: 3px solid #fff!important;
    line-height: 18px;
}

.sideNavs:hover {
    border-left: 3px solid #34babc!important;
    border-bottom: none!important;
    margin-left: 10px;
	border-radius: 0px!important;
	transition: 0.4s;
	color: #34babc;
}

.sideNavs.active {
    border-left: 3px solid #34babc!important;
    border-bottom: none!important;
    margin-left: 10px;
	border-radius: 0px!important;
	transition: 0.4s;
	color: #34babc!important;
}

.addLista {
    position: absolute;
    top: 0px;
    right: 17px;
}

.nav-tabs > li:hover > span {
    display:block;
}

.list-group-item {
	border-radius: 0px!important;
}

.mt-25 {margin-top:12px;}

.editInput {
    overflow: visible!important;
    background: #edf1f5!important;
    top: 0px!important;
    position: relative!important;
    margin-top: 5px!important;
    border: none!important;
    margin-left: 10px!important;
    padding: 5px 0px 5px 10px!important;
    border-radius: 5px!important;
    max-width: 190px!important;
}


#alert_TooltipOne {
    background-color: #903693;
    position: absolute;
    top: -42px;
    width: 330px;
    left: 250px;
    line-height: 24px;
    font-size: 15px!important;
    text-align: left;
    border: none;
    padding: 20px!important;
    z-index: 1050;
}

#alert_TooltipOne:before {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: -30px;
    border-style: solid;
    border-width: 25px 25px 0;
    border-color: #903693 transparent;
    display: block;
    width: 0;
    z-index: 1;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}
#alert_TooltipTwo {
    background-color: #903693;
    position: absolute;
    top: -130px;
    width: 350px;
    margin-left: 0;
    z-index: 1050;
    line-height: 24px;
    font-size: 15px!important;
    text-align: left;
    border: none;
    padding: 20px!important;
    right: 40%;
}
#alert_TooltipTwo:before {
    content: "";
    position: absolute;
    bottom: -15px;
    left: calc(50% - 25px);
    border-style: solid;
    border-width: 25px 25px 0;
    border-color: #903693 transparent;
    display: block;
    width: 0;
    z-index: 1;
}

.alertClose {color:#fff;font-weight: bold;font-size: 14px;line-height: 16px;text-transform: uppercase;transition: 0.5s;}
.alertClose:hover {color:#fff;opacity:0.5;text-decoration: none;transition: 0.5s;}

.alert-dark {
    color: #ffffff;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

#alert-fadein {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
	padding: 5px 45px 5px 45px;
	border-radius: 5px;
	position: absolute;
	top: -25px;/*-80px*/
	text-align: center;
	font-weight: 300;
	width: 100%;
	min-height: 48px;
	vertical-align: middle;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadein {
from {opacity:0;}to {opacity:1;}}
@-moz-keyframes fadein { /* Firefox */
from {opacity:0;}to {opacity:1;}}
@-webkit-keyframes fadein { /* Safari and Chrome */
from {opacity:0;}to {opacity:1;}}
@-o-keyframes fadein { /* Opera */
from {opacity:0;}to {opacity: 1;}}

.alert {
  	position: relative;
}

.alert {
  	padding: 0.56rem 1.25rem;
 	margin-bottom: 1rem;
 	border: 1px solid transparent;
 	border-radius: 0.25rem;
	font-size: 13px!important;
	font-weight: 300;
	line-height: 16px;
}

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.35rem 1rem;
    color: inherit; }
.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

@media screen and (prefers-reduced-motion: reduce) {
.fade {
	-webkit-transition: none;
	transition: none; } }

.fade:not(.show) {
    opacity: 0; }

.fade.in {
    opacity: 1;
}

.img-event .card-img-top {
    height: 90px !important;
}

.body-resource-height .badge {
  cursor: pointer;
}

.filterActive {
    padding: 4px 14px;
    font-size: 18px;
}

.blue {color:#1bcaca;}

.p-10-0 {
    padding: 10px 0px;
}

.height-carousel {
    height: 140px;
}

@media screen and (min-width: 1546px) {
    .card-search {height: 376px!important;}
    .searchResultBox {height: 200%!important;}
    .carousel-dots {margin-bottom: 2%!important;}
}

@media screen and (max-width: 1200px) {
    .slick-list {width: 100%;}
    .body-resource-height {width: 100%!important}
}


@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .carousel-dots {margin-bottom: 0!important;}
}

@media screen and (min-width: 768px) and (max-width: 769px) {
    .d-md-block {
        display: none!important;
    }
}

.ck-content.noticias * {
    word-break: break-all;
}

.hyphenate {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}