.icono {
    background-position: center center;
    background-size: contain;
    height: 60px;
    width: 60px;
    position: relative;
    margin-right: auto;
    margin-left: 20px;
    margin-bottom: 0px;
    margin-top: -65px;
    border-radius: 50%;
    box-shadow: none!important;
}

.icono-buscador {
    background-position: center center;
    background-size: contain;
    position: relative;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    box-shadow: none!important;
}

.icono-recursos {
    background-position: center center;
    background-size: contain;
    position: relative;
    border-radius: 50%;
    margin-left: 0px;
    margin-top: 0;
    width: 30px;
    height: 30px;
    box-shadow: none!important;
}

.icono-carousel {
	background-position: center center;
    background-size: contain;
    height: 20px;
    width: 20px;
    position: relative;
    margin: -20px 0px 0px 10px;
    border-radius: 2px;
}

.icono-carousel-buscador {
	background-position: center center;
    background-size: contain;
    height: 50px;
    width: 50px;
    position: absolute;
    margin: 15px 0px 0px 5px;
    border-radius: 2px;
	top: 35px;
	left: 5px;
	z-index: 1004;
	border-radius: 50%;
}

.icono-home {
	background-position: center center;
	background-size:contain;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
}

.icono-home-lg {
	background-position: center center;
	background-size:contain;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
}

.icono-home-xl {
	background-position: center center;
	background-size:contain;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
}

.icono-xs {
	background-position: center center;
	background-size:contain;
	height: 40px;
	width: 40px;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 20px;
	margin-top: -10px;
}

.icono-act {
	background-position: center center;
	background-size:contain;
	height: 60px;
	width: 60px;
	position: relative;
	margin: 1px;
	padding: 0px;
}


.icono-user-panel {
	background-position: center center;
    background-size: contain;
    height: 0.9rem;
    width: 0.9rem;
    position: relative;
    margin: 0px 5px 0px 0px;
    border-radius: 2px;
    vertical-align: middle;
}

.grayed-out {
	 opacity: 0.3;
    filter: alpha(opacity=30); /* For IE8 and earlier */
	cursor: not-allowed;
}

.audio {background-image: url(iconos/Formato_Audio.svg);}
.biblioteca {background-image: url(iconos/biblioteca.svg);}
.biblioteca-home {background-image: url(iconos/bibliotecaHome.svg);}
.clase {background-image: url(iconos/clase.svg);}
.contacto {background-image: url(iconos/contacto.svg);}
.dialogos {background-image: url(iconos/dialogos.svg);}
.educacion {background-image: url(iconos/educacion.svg);}
.encuentro {background-image: url(iconos/encuentro.svg);}
.expertos {background-image: url(iconos/expertos.svg);}
.favorito {background-image: url(iconos/favorito.svg);}
.galerias {background-image: url(iconos/Formato_GaleriaDeImagenes.svg);}
.icono_api {background-image: url("../images/JM_API.svg");}
.icono_oai {background-image: url("../images/JM_OAI.svg");}
.juegos {background-image: url(iconos/Formato_Interactivo.svg);}
.libros {background-image: url(iconos/Formato_LibroElectronico.svg);}
.misaulas {background-image: url(iconos/misaulas.svg);}
.mis_aulas {background-image: url(iconos/mis_aulas.svg);}
.mibiblioteca {background-image: url(iconos/mibiblioteca.svg);}
.mi_biblioteca {background-image: url(iconos/mi_biblioteca.svg);}
.misdatos {background-image: url(iconos/misdatos.svg);}
.miformacion {background-image: url(iconos/miformacion.svg);}
.mi_formacion {background-image: url(iconos/mi_formacion_2.svg);}
.mislikes {background-image: url(iconos/mislikes.svg);}
.miperfil {background-image: url(iconos/miperfil.svg);}
.mi_perfil {background-image: url(iconos/mi_perfil_2.svg);}
.mis_recursos {background-image: url(iconos/mis_recursos.svg);}
.mispostitulos {background-image: url(iconos/MEDALLA_MIS_POSTULOS.svg);}
.mis_postitulos {background-image: url(iconos/mis_postitulos_3.svg);}
.panel {background-image: url(iconos/panel.svg);}
.pdf {background-image: url(iconos/PDF_icon.svg);}
.pizarron {background-image: url(iconos/pizarron.svg);}
.textos {background-image: url(iconos/Formato_Texto.svg);}
.videos {background-image: url(iconos/Formato_Video.svg);}

.aulasvirtuales {background-image: url(iconos/aulasvirtuales.svg);}
.bibliotecasdigitales {background-image: url(iconos/bibliotecasdigitales.svg);}
.comentario {background-image: url(iconos/comentario.svg);}
.data {background-image: url(iconos/data.svg);}
.doc123 {background-image: url(iconos/123.svg);}
.documento {background-image: url(iconos/documento.svg);}
.educar {background-image: url(iconos/educar.svg);}
.libro {background-image: url(iconos/libro.svg);}
.libroabierto {background-image: url(iconos/libroabierto.svg);}
.lupa {background-image: url(iconos/lupa.svg);}
.seguimoseducando {background-image: url(iconos/seguimoseducando.svg);}

.arrow-prev-icon {
    background-image: url(./iconos/flecha_carrousel.svg) !important;
}
.arrow-next-icon {
    background-image: url(./iconos/flecha_carrousel.svg) !important;
    transform: rotate(180deg);
}

.slick-prev:before, .slick-next:before { display: none !important; }

.icono_play {
    height: 80px!important;
    width: 140px!important;
    position: relative!important;
    margin: 0px;
    left: 0!important;
    padding: 0px;
    top: 0px!important;
}

.play {
    background-image: url(iconos/play.svg);
    position: relative;
    top: 0px;
    z-index: 1004;
    width: 50px;
    height: 50px;
    left: 23px;
    cursor: pointer;
}

.play:hover {
    opacity: 0.5;
	transition: 0.4s;
}
.icon-shadow {
  -webkit-filter: drop-shadow( 2px 2px 4px rgba(0, 0, 0, 0.3));
  filter: drop-shadow( 2px 2px 4px rgba(0, 0, 0, 0.3));
}